import { AdminSharedAsset } from '../components/SharedAsset';

function SharedMediaRoute() {
  return <AdminSharedAsset />;
}

export const Component = SharedMediaRoute;

export function clientLoader() {
  return null;
}
